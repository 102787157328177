import { createContext } from "react";

type LoadedAuth = {
  status: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};
type AuthUser = {
  setLoadedAuth: (data: LoadedAuth) => void;
  loadedAuth: LoadedAuth;
};
export const AuthUserContext = createContext<AuthUser>({
  loadedAuth: {
    status: false,
    data: null,
  },
  setLoadedAuth: () => {},
});
