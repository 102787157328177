import image from "@doar/shared/images/img19.png";
import {
  StyledWrap,
  StyledImg,
  StyledTitle,
  StyledSubTitle,
  StyledDesc,
} from "./style";

const PermissionErrorContainer = ({ message }: { message: string }) => {
  return (
    <StyledWrap>
      <StyledImg>
        <img src={image} alt="Error" />
      </StyledImg>
      <StyledTitle>403 Access Denied</StyledTitle>
      <StyledSubTitle>{message}</StyledSubTitle>
      <StyledDesc>
        Please contact your administrator to get the permission.
      </StyledDesc>
    </StyledWrap>
  );
};

export default PermissionErrorContainer;
