import Content from "../layouts/content";
import ErrorContainer from "../containers/error-403";
import SEO from "../components/seo";

const ErrorNotFound = ({ message }: { message: string }) => {
  return (
    <>
      <SEO />
      <Content fullHeight align="center">
        <ErrorContainer message={message} />
      </Content>
    </>
  );
};

export default ErrorNotFound;
