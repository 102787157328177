import { SpaceProps } from "@doar/shared/styled";
import { StyledContainer } from "./style";
import { GridThemeProvider } from "styled-bootstrap-grid";

interface IProps extends SpaceProps {
  children: React.ReactNode;
  className?: string;
}

const gridTheme = {
  breakpoints: {
    mega: 2500,
    xxxl: 2400,
    xxl: 1500,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
  },
  container: {
    maxWidth: {
      mega: 2450,
      xxxl: 1933,
      xxl: 1440,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
    },
  },
};
const Container = ({ className, ...props }: IProps) => {
  return (
    <GridThemeProvider gridTheme={gridTheme}>
      <StyledContainer className={className} {...props} />
    </GridThemeProvider>
  );
};

export default Container;
