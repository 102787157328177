import { Heart } from "react-feather";
import {
  StyledFooter,
  StyledFooterLeft,
  StyledFooterRight,
  StyledFooterNav,
  StyledFotterNavLink,
} from "./style";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterRight>
        <StyledFooterNav>
          <StyledFotterNavLink path="https://themeforest.net/licenses/standard">
            Licenses
          </StyledFotterNavLink>
          <StyledFotterNavLink path="/">Change Log</StyledFotterNavLink>
          <StyledFotterNavLink path="https://hasthemes.com/contact-us/">
            Get Help
          </StyledFotterNavLink>
        </StyledFooterNav>
      </StyledFooterRight>
      <StyledFooterLeft>
        <span>&copy; Unblock {new Date().getFullYear()} </span>
      </StyledFooterLeft>
    </StyledFooter>
  );
};

export default Footer;
