import { useCallback, useState } from "react";
import { IMenu } from "@doar/shared/types";
import { getSiblings } from "@doar/shared/methods";
import { useClickOutside } from "@doar/shared/hooks";
import { StyledNavbar, StyledNavitem, StyledNavlink } from "./style";
import Submenu from "./submenu";
import Megamenu from "./megamenu";

interface IProps {
  menus: IMenu[];
  onMenuItemClick: () => void;
  activeItem: string;
}

const Navbar = ({ menus, onMenuItemClick, activeItem }: IProps) => {
  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);

  const toggleSubmenu = (id: number) => {
    setOpenSubmenu((prevOpen) => (prevOpen === id ? null : id));
  };

  const closeSubmenu = () => {
    setOpenSubmenu(null);
    onMenuItemClick();
  };

  const clickHandler = (
    e: React.MouseEvent,
    hasChildren: boolean,
    customOnClick?: () => void
  ) => {
    if (hasChildren) {
      e.preventDefault();
      let target = e.currentTarget as HTMLElement;
      if (target.nodeName === "A") {
        target = target.parentElement as HTMLElement;
      }
      const submenu = target.querySelector(".submenu");
      const siblings = getSiblings(target);
      submenu?.classList.toggle("open");
      siblings.forEach((sib) => {
        sib.childNodes.forEach((child) => {
          const childHT = child as HTMLElement;
          childHT?.classList?.remove("open");
        });
      });
    } else {
      if (onMenuItemClick) {
        onMenuItemClick();
      }
    }

    if (customOnClick) {
      customOnClick();
    }
  };

  const onClose = useCallback(() => {
    const nav = document.querySelector(".navbar");
    const submenu = nav?.querySelectorAll(".submenu");
    submenu?.forEach((el) => el.classList.remove("open"));
  }, []);

  const containerRef = useClickOutside<HTMLUListElement>(onClose);

  return (
    <StyledNavbar ref={containerRef} className="navbar">
      {menus &&
        menus.map((nav: IMenu) => {
          const {
            submenu,
            megamenu,
            id: navId,
            Icon: NavIcon,
            label: navLabel,
            url,
          } = nav;
          const hasSubmenu = !!submenu?.length;
          const hasMegamenu = !!megamenu?.length;
          const hasChildren = hasSubmenu || hasMegamenu;
          const isActive = hasSubmenu
            ? submenu?.find(({ url }) => url === activeItem)
            : url === activeItem;

          return (
            <StyledNavitem
              $hasSubmenu={hasChildren}
              key={navId}
              onClick={(e) => {
                clickHandler(e, hasChildren, nav.onClick);
                toggleSubmenu(navId);
              }}
            >
              <StyledNavlink
                path={hasChildren ? "#!" : url}
                className={isActive ? "active" : ""}
              >
                {NavIcon && <NavIcon />}
                {navLabel}
              </StyledNavlink>
              {submenu && (
                <Submenu
                  menu={submenu}
                  closeMenu={closeSubmenu}
                  isOpen={openSubmenu === navId}
                  activeItem={activeItem}
                />
              )}
              {megamenu && <Megamenu menu={megamenu} activeItem={activeItem} />}
            </StyledNavitem>
          );
        })}
    </StyledNavbar>
  );
};

export default Navbar;
