export const normalizePath = (path: string) => {
  const segments = path.split("/").filter(Boolean);

  const idRegex = /^[0-9a-fA-F-]{36}$|^\d+$/;

  if (segments.length > 1 && idRegex.test(segments[segments.length - 1])) {
    segments.pop();
  }

  return "/" + segments.join("/");
};
