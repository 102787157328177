import { DollarSign, Settings, AlertTriangle } from "react-feather";
import { IMenu } from "@doar/shared/types";

const menus: IMenu[] = [
  {
    id: 56,
    label: "User",
    url: "/user",
    groups: ["Admin", "Viewer", "EPMAP"],
  },
  {
    id: 1,
    label: "Analytics",
    url: "/",
    groups: ["Admin", "Viewer"],
    submenu: [
      {
        id: 11,
        label: "General Analytics",
        url: "/",
        groups: ["Admin", "Viewer"],
      },
      {
        id: 12,
        label: "Remittance Analytics",
        url: "/remittance-analytics",
        groups: ["Admin", "Viewer"],
      },
      {
        id: 13,
        label: "Liquidity Pool",
        url: "/liquidity-pool",
        groups: ["Admin", "Viewer"],
      },
    ],
  },
  {
    id: 2,
    label: "Transactions",
    url: "/transactions",
    groups: ["Admin", "Merchant", "Viewer", "EPMAP"],
  },
  {
    id: 3,
    label: "Merchants",
    url: "/merchants",
    groups: ["Admin", "Viewer"],
  },
  {
    id: 4,
    label: "Corporate",
    url: "/corporates",
    groups: ["Admin", "Viewer"],
  },
  {
    id: 5,
    label: "Users",
    url: "/users",
    groups: ["Admin", "Viewer", "EPMAP"],
  },
  {
    id: 6,
    label: "Liquidity",
    url: "/liquidity",
    groups: ["Admin", "Viewer"],
  },
  {
    id: 7,
    label: "Alerts",
    url: "/",
    groups: ["Admin", "Viewer"],
    submenu: [
      {
        id: 71,
        label: "Risk",
        url: "/alerts/risk",
        Icon: AlertTriangle,
        groups: ["Admin", "Viewer"],
      },
      {
        id: 72,
        label: "Operation",
        url: "/alerts/operation",
        Icon: Settings,
        groups: ["Admin", "Viewer"],
      },
      {
        id: 73,
        label: "Treasury",
        url: "/alerts/treasury",
        Icon: DollarSign,
        groups: ["Admin", "Viewer"],
      },
    ],
  },
  {
    id: 8,
    label: "Parameters",
    url: "/parameters",
    groups: ["Admin"],
  },
  {
    id: 9,
    // TODO: Add `Reports` label reports menu when it's ready
    label: "",
    url: "/reports",
    groups: ["Admin", "Viewer", "EPMAP"],
  },
];

export default menus;
