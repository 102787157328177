/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, X, ArrowLeft } from "react-feather";
import { Navbar, Button } from "@doar/components";
import { menuData } from "@doar/shared/data";
import ProfileDropdown from "../../components/header/profile-dropdown";
import NavSearch from "../../components/header/nav-search";
import Logo from "../../components/logo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleSidebar, toggleBody } from "../../redux/slices/ui";
import { AuthUserContext } from "../../provider";
import {
  StyledHeader,
  StyledLogo,
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyleNavbarRight,
  StyledNavbarElement,
  StyledNavbarHeader,
  StyledNavbarBody,
  StyledNavbarTitle,
  StyledMenuBtn,
  StyledSidebarBtn,
} from "./style";
import { IMenu } from "@doar/shared/types";

interface IProps {
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
}

const Header = ({ hasSidebar, sidebarLayout }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sidebar, isBody } = useAppSelector((state) => state.ui);
  const { loadedAuth } = useContext(AuthUserContext);
  const isEPMAPGroup = loadedAuth?.data?.groups.includes("EPMAP");
  const [searchOpen, setSearchOpen] = useState(false);
  const searchHandler = useCallback(() => {
    setSearchOpen((prev) => !prev);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarHandler = useCallback(
    (_: any, isOpen?: "open") => {
      dispatch(toggleSidebar({ isOpen }));
    },
    [dispatch]
  );
  const [submenuOpen, setSubmenuOpen] = useState("");
  const submenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target as Node)
      ) {
        setSubmenuOpen("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSubmenu = (label: string) => {
    setSubmenuOpen((prev) => (prev === label ? "" : label));
  };

  const bodyHandler = useCallback(() => {
    dispatch(toggleBody());
    dispatch(toggleSidebar({ isOpen: "open" }));
  }, [dispatch]);

  const menuHandler = useCallback(() => {
    setMenuOpen((prev) => !prev);
    if (menuOpen) {
      sidebarHandler(undefined, "open");
    }
  }, [menuOpen, sidebarHandler]);

  const handleSubmenuClick = (label: string) => {
    if (label === "Risk" || label === "Operation" || label === "Treasury") {
      navigate(`/alerts/${label.toLowerCase()}`);
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    return () => {
      sidebarHandler(undefined, "open");
      bodyHandler();
    };
  }, [sidebarHandler, bodyHandler]);

  let parsedMenus = [...menuData].filter((m: IMenu) => m.label !== "User");

  if (isEPMAPGroup) {
    parsedMenus = menuData.filter(
      (m: IMenu) => m.label === "Transactions" || m.label === "Users"
    );
  }

  return (
    <>
      <StyledHeader>
        {hasSidebar && sidebarLayout === 1 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="menu-btn"
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn
              variant="texted"
              onClick={!isBody ? sidebarHandler : bodyHandler}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="sidebar-btn"
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {hasSidebar && sidebarLayout === 2 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={!sidebar}
              $bodyOpen={false}
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn
              variant="texted"
              onClick={sidebarHandler}
              $sidebarOpen={!sidebar}
              $bodyOpen={false}
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {!hasSidebar && (
          <StyledMenuBtn
            variant="texted"
            onClick={menuHandler}
            $hasSidebar={hasSidebar}
            $sidebarOpen={!sidebar}
          >
            <Menu size={20} strokeWidth="2.5px" />
          </StyledMenuBtn>
        )}
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledNavbarWrap
          $isOpen={menuOpen}
          onClick={menuHandler}
          className="navbar-wrap"
        >
          <StyledNavbarMenu
            $isOpen={menuOpen}
            ref={submenuRef}
            onClick={(e) => e.stopPropagation()}
          >
            <StyledNavbarHeader>
              <Logo />
              <Button variant="texted" onClick={menuHandler}>
                <X color="#7987a1" width={20} strokeWidth="2.5px" />
              </Button>
            </StyledNavbarHeader>
            <StyledNavbarBody>
              <StyledNavbarTitle>MAIN NAVIGATION</StyledNavbarTitle>
              {loadedAuth?.data && (
                <Navbar
                  menus={parsedMenus.reduce((result: IMenu[], menu) => {
                    if (menu.submenu) {
                      menu.submenu = menu.submenu.map((submenuItem) => {
                        return {
                          ...submenuItem,
                          onClick: () => {
                            handleSubmenuClick(submenuItem.label);
                            toggleSubmenu(submenuItem.label);
                          },
                        };
                      });
                    }
                    return [...result, menu];
                  }, [])}
                  onMenuItemClick={() => setMenuOpen(false)}
                  activeItem={location.pathname}
                />
              )}
            </StyledNavbarBody>
          </StyledNavbarMenu>
        </StyledNavbarWrap>
        <StyleNavbarRight>
          <StyledNavbarElement ml={["15px", "20px", "30px"]}>
            <ProfileDropdown />
          </StyledNavbarElement>
        </StyleNavbarRight>
      </StyledHeader>
      <NavSearch isOpen={searchOpen} onClose={searchHandler} />
    </>
  );
};

Header.defaultProps = {
  sidebarLayout: 1,
};

export default Header;
