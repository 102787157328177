import React, { useContext, useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { menuData } from "@doar/shared/data";
import { AuthUserContext } from "./provider";
import { signOut } from "aws-amplify/auth";
import ErrorAccessDenied from "./pages/error-403";
import { Spinner } from "@doar/components";
import { ISubmenu } from "@doar/shared/types";
import { LoadingOverlay } from "./containers/alert/style";
import { normalizePath } from "../src/utils/normalizePath";

export const RequireAuth = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { loadedAuth, setLoadedAuth } = useContext(AuthUserContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loadedAuth.status) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [loadedAuth.status]);

  if (isLoading) {
    return (
      <LoadingOverlay>
        <Spinner />
      </LoadingOverlay>
    );
  }

  if (loadedAuth.status && !loadedAuth.data) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (loadedAuth.status) {
    const userValidMenus = menuData.filter(({ groups }) =>
      groups?.find((g) => loadedAuth.data.groups?.includes(g))
    );

    const expandedMenuData = menuData.flatMap((menu) =>
      menu.submenu
        ? menu.submenu.map((subItem: ISubmenu | any) => ({
            ...subItem,
            groups: menu.groups,
          }))
        : menu
    );

    const normalizedPath = normalizePath(location.pathname);

    const currentGroupMenu = expandedMenuData.find((menu) =>
      menu.url.includes(normalizedPath)
    )?.groups;

    const userHasAccess = currentGroupMenu
      ? loadedAuth.data.groups.some((group: string) =>
          currentGroupMenu.includes(group)
        )
      : false;

    if (!userHasAccess) {
      const errorMessage =
        location.pathname === "/parameters"
          ? "You don’t have permission to update the NGN markup."
          : "Oops, you don’t have permission to this tab.";
      return <ErrorAccessDenied message={errorMessage} />;
    }

    if (
      !userValidMenus.find(({ url }) => location.pathname.includes(url)) &&
      userValidMenus[0]?.url
    ) {
      return <Navigate to={userValidMenus[0].url ?? "/"} replace />;
    }
    if (!userValidMenus[0]?.url) {
      signOut().then(() => {
        setLoadedAuth({ status: true, data: null });
        return <Navigate to="/signin" state={{ from: location }} replace />;
      });
    }
  }
  return children;
};
