import styled, { themeGet } from "@doar/shared/styled";
export const LoadingOverlay = styled.div`
  display: flex;
  position: absolute;
  background-color: #0001;
  box-shadow: inset 0px 0px 20px 10px ${themeGet("colors.background")};
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
`;

export const FullHeightAlignCenter = styled.div`
  display: grid;
  place-items: center;
  min-height: calc(92vh - 108px);
`;
