import styled, {
  space,
  css,
  device,
  SpaceProps,
  border,
  BorderProps,
  typography,
  TypographyProps,
} from "@doar/shared/styled";
import { Container, Row, Col } from "styled-bootstrap-grid";

type IProps = SpaceProps;

const xxxl = (styles: TemplateStringsArray | string) => css`
  @media (min-width: 2400px) {
    ${styles}
  }
`;

const mega = (styles: TemplateStringsArray | string) => css`
  @media (min-width: 2560px) {
    ${styles}
  }
`;

export const StyledContainer = styled(
  ({ p, pl, pr, pt, pb, m, ml, mr, mt, mb, ...props }) => (
    <Container {...props} />
  )
)<IProps>`
  ${space};
  ${xxxl`
    font-size: 25px;
    max-width: 2320px;
     * { font-size: 25px !important; }
  `}
  ${mega`
    font-size: 25px;
    max-width: 2520px;
     * { font-size: 25px !important; }
  `}
`;

export const StyledRow = styled(
  ({ p, pl, pr, pt, pb, m, ml, mr, mt, mb, ...props }) => <Row {...props} />
)<IProps>`
  ${space};
  ${({ $gutters }) =>
    !!$gutters &&
    css`
      margin-left: -${$gutters / 2}px;
      margin-right: -${$gutters / 2}px;
      & > div {
        padding-left: ${$gutters / 2}px;
        padding-right: ${$gutters / 2}px;
      }
    `}
  ${({ $noGutter }) =>
    $noGutter === true &&
    css`
      margin-left: 0px;
      margin-right: 0px;
      & > div {
        padding-left: 0px;
        padding-right: 0px;
      }
    `}
  ${xxxl`
    font-size: 25px;
     * { font-size: 25px !important; }
  `}
  ${mega`
    font-size: 25px;
     * { font-size: 25px !important; }
  `}
`;

interface ICol extends IProps, BorderProps, TypographyProps {}

export const StyledCol = styled(
  ({ p, pl, pr, pt, pb, m, ml, mr, mt, mb, textAlign, ...props }) => (
    <Col {...props} />
  )
)<ICol>`
  ${device.small} {
    &.order-sm-0 {
      order: 0;
    }
  }
  ${device.medium} {
    &.order-md-0 {
      order: 0;
    }
  }
  ${device.large} {
    &.order-lg-0 {
      order: 0;
    }
  }
  ${device.xlarge} {
    &.order-xl-0 {
      order: 0;
    }
  }
  ${space};
  ${border};
  ${typography};
  ${xxxl`
    font-size: 25px;
         * { font-size: 25px !important; }
  `}
  ${mega`
    font-size: 25px;
         * { font-size: 25px !important; }
  `}
`;
