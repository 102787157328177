/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Amplify } from "aws-amplify";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import App from "./App";

// eslint-disable-next-line @typescript-eslint/no-var-requires
let config: Record<string, string> = {};
try {
  config = require("./amplifyconfiguration.json");
  // eslint-disable-next-line no-empty
} catch (_) {}

const configureOptions = {
  Auth: {
    Cognito: {
      userPoolId:
        config.aws_user_pools_id || process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId:
        config.aws_user_pools_web_client_id ||
        process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
  },
  API: {
    GraphQL: {
      endpoint:
        config &&
        "aws_appsync_graphqlEndpoint" in config &&
        config?.aws_appsync_graphqlEndpoint
          ? (config?.aws_appsync_graphqlEndpoint as string)
          : process.env.REACT_APP_API_URL ?? "",
      region:
        config && "aws_appsync_region" in config && config?.aws_appsync_region
          ? (config?.aws_appsync_region as string)
          : process.env.REACT_APP_REGION ?? "eu-west-2",
      defaultAuthMode: "userPool",
    },
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Amplify.configure(configureOptions, {
  ssr: true,
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </PersistProvider>
  </Provider>
);
