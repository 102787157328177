import { ISubmenu } from "@doar/shared/types";
import { StyledSubmenu, StyledSubNavItem, StyledSubNavlink } from "./style";
import { SyntheticEvent } from "react";

interface IProps {
  menu: ISubmenu[];
  closeMenu: () => void;
  activeItem: string;
  isOpen: boolean;
}

const Submenu = ({ menu, closeMenu, activeItem, isOpen }: IProps) => {
  return (
    <StyledSubmenu $isOpen={isOpen}>
      {menu.map(({ id, label, url, Icon, onClick }) => (
        <StyledSubNavItem key={id}>
          <StyledSubNavlink
            path={url}
            className={url === activeItem ? "active" : ""}
            onClick={(e: SyntheticEvent) => {
              if (onClick) {
                e.stopPropagation();
                onClick();
              }
              closeMenu();
            }}
          >
            {Icon && <Icon />}
            {label}
          </StyledSubNavlink>
        </StyledSubNavItem>
      ))}
    </StyledSubmenu>
  );
};

export default Submenu;
