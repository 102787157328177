import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "react-feather";
import { signOut } from "aws-amplify/auth";
import {
  DropdownToggle,
  Dropdown,
  Avatar,
  AvatarInitial,
  Anchor,
} from "@doar/components";
import { AuthUserContext } from "../../../provider";
import {
  StyledDropMenu,
  StyledAuthorName,
  StyledDropItem,
  StyledAvatar,
  StyledOption,
} from "./style";

const ProfileDropdown = () => {
  const { loadedAuth, setLoadedAuth } = useContext(AuthUserContext);
  const navigate = useNavigate();

  const logOut = async () => {
    await signOut();
    setLoadedAuth({ status: true, data: null });
    navigate("/signin");
  };
  const initials = (loadedAuth.data?.attributes?.name ?? "")
    ?.split(" ")
    .map((n: string) => n[0])
    .join("");
  return (
    <Dropdown direction="down" className="dropdown-profile">
      <DropdownToggle variant="texted">
        <StyledAvatar size="sm" shape="circle">
          <AvatarInitial>{initials}</AvatarInitial>
        </StyledAvatar>
      </DropdownToggle>
      <StyledDropMenu>
        <Avatar size="lg" shape="circle">
          <AvatarInitial>{initials}</AvatarInitial>
        </Avatar>
        <StyledAuthorName>
          {loadedAuth.data?.attributes?.name ?? ""}
        </StyledAuthorName>
        <StyledOption>
          <Anchor path="/update-password">Change password</Anchor>
        </StyledOption>
        <StyledDropItem path="#" onClick={logOut} mt="10px">
          <LogOut size="24" />
          Sign Out
        </StyledDropItem>
      </StyledDropMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
