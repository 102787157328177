import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const EPMAP_ALLOWED_ROUTES: Record<string, boolean> = {
  transactions: true,
  users: true,
  signin: true,
  signup: true,
  "verify-account": true,
  "forgot-password": true,
  "update-password": true,
  "error-500": true,
  "error-503": true,
  "error-505": true,
  "error-404": true,
};
const DEFAULT_EPMAP_ROUTE = "/transactions";

const EPMAP_ROUTE_EXCEPTIONS: Record<string, boolean> = {
  "/user/": true,
};

export const useRouteGuard = () => {
  const loadedAuth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isEPMAPGroup = useMemo(() => {
    return loadedAuth?.data?.groups.includes("EPMAP");
  }, [loadedAuth]);

  useEffect(() => {
    const currentRoute = location.pathname.split("/")[1];

    const isExceptionRoute = Object.keys(EPMAP_ROUTE_EXCEPTIONS).some((route) =>
      location.pathname.startsWith(route)
    );

    if (EPMAP_ALLOWED_ROUTES[currentRoute] || isExceptionRoute || !isEPMAPGroup)
      return;

    if (location.pathname !== DEFAULT_EPMAP_ROUTE) {
      navigate(DEFAULT_EPMAP_ROUTE);
    }
  }, [location, isEPMAPGroup, navigate]);

  return undefined;
};
