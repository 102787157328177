import { StyledLogo } from "./style";
import localLogo from "../../assets/logo.svg";

const Logo = () => {
  return (
    <StyledLogo path="/">
      <img
        src={localLogo}
        alt="Logo"
        style={{ width: "130px", height: "auto" }}
      />
    </StyledLogo>
  );
};

export default Logo;
